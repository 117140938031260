import React, { useRef, useState } from 'react';

interface InputSpotlightBorderProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string; // Ошибка валидации (необязательно)
}

export const InputSpotlightBorder: React.FC<InputSpotlightBorderProps> = ({ value, onChange, error }) => {
    const divRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [opacity, setOpacity] = useState(0);

    const handleMouseMove = (e: React.MouseEvent<HTMLInputElement>) => {
        if (!divRef.current || isFocused) return;

        const div = divRef.current;
        const rect = div.getBoundingClientRect();

        setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    };

    const handleFocus = () => {
        setIsFocused(true);
        setOpacity(1);
    };

    const handleBlur = () => {
        setIsFocused(false);
        setOpacity(0);
    };

    const handleMouseEnter = () => {
        setOpacity(1);
    };

    const handleMouseLeave = () => {
        setOpacity(0);
    };

    return (
        <>
            <div className='relative'>
                <input
                    value={value} // Передача значения
                    onChange={onChange} // Передача функции изменения
                    onMouseMove={handleMouseMove}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className={`h-12 w-full cursor-default rounded-md border ${
                        error ? 'border-red-500' : 'border-slate-800'
                    } bg-neutral-950 p-3.5 text-[16px] text-slate-100 transition-colors duration-500 placeholder:select-none placeholder:text-neutral-500 focus:border-[#2041e4] focus:outline-none`}
                />
                {error && <p className='text-red-500 text-sm'>{error}</p>} {/* Отображение ошибки, если есть */}
                <div
                    ref={divRef}
                    style={{
                        border: '1px solid rgb(32 65 228)',
                        opacity,
                        WebkitMaskImage: `radial-gradient(30% 30px at ${position.x}px ${position.y}px, black 45%, transparent)`,
                    }}
                    aria-hidden='true'
                    className='pointer-events-none absolute left-0 top-0 z-10 h-12 w-full cursor-default rounded-md border bg-[transparent] p-3.5 opacity-0 transition-opacity duration-500'
                ></div>
            </div>
        </>
    );
};
