'use client';
import { StoreApi, UseBoundStore } from 'zustand';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
interface ModeStore {
    timeCoef: number;
    targetTimeCoef: number;
    hyperMode: boolean;
    turboMode: boolean;
    zoomStrength: number;
    setZoomStrength: (strength: number) => void; // Метод для установки значения

    setHyperMode: (hm: boolean) => void;
    setTurboMode: () => void;
    changeHyperMode: () => void;
    changeTurboMode: () => void;
    initTurboMode: () => void;
    setTimeCoef: (tc: number) => void;
    setTargetTimeCoef: (ttc: number) => void;
    moveOneStep: () => void;
}
const useStore = create<ModeStore>()(
    persist(
        (set) => ({
            timeCoef: 1,
            targetTimeCoef: 1,
            hyperMode: false,
            turboMode: false,
            zoomStrength: 0,
            setZoomStrength: (strength) => set({ zoomStrength: strength }), // Метод для изменения zoomStrength
            setHyperMode: (hm) =>
                set({
                    hyperMode: hm,
                }),
            initTurboMode: () =>
                set((state) =>
                    state.hyperMode == true
                        ? {
                              targetTimeCoef: 100,
                          }
                        : {
                              targetTimeCoef: 1,
                          }
                ),
            setTurboMode: () =>
                set({
                    turboMode: false,
                }),
            changeHyperMode: () =>
                set((state) =>
                    state.hyperMode == true
                        ? {
                              hyperMode: false,
                              targetTimeCoef: 1,
                          }
                        : {
                              hyperMode: true,
                              targetTimeCoef: 100,
                          }
                ),
            changeTurboMode: () => {
                set((state) =>
                    state.hyperMode == true
                        ? {
                              turboMode: state.turboMode,
                          }
                        : {
                              turboMode: true,
                              targetTimeCoef: 50,
                          }
                );
            },
            setTimeCoef: (tc) => set({ timeCoef: tc }),
            setTargetTimeCoef: (ttc) => set({ targetTimeCoef: ttc }),
            moveOneStep: async () =>
                set((state) => {
                    return state.turboMode
                        ? {
                              timeCoef: state.timeCoef + (state.targetTimeCoef - state.timeCoef) * 0.02,
                              targetTimeCoef: state.timeCoef > 40 ? 1 : state.targetTimeCoef,
                              turboMode: state.timeCoef < 10 && state.targetTimeCoef == 1 ? false : true,
                          }
                        : {
                              timeCoef: state.timeCoef + (state.targetTimeCoef - state.timeCoef) * 0.02,
                          };
                }),
        }),
        {
            name: 'mode-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

type WithSelectors<S extends UseBoundStore<StoreApi<any>>> = S & {
    use: {
        [K in keyof ReturnType<S['getState']>]: () => ReturnType<S['getState']>[K];
    };
};

const createSelectors = <S extends UseBoundStore<StoreApi<any>>>(_store: S) => {
    const store = _store as WithSelectors<S>;
    store.use = {} as WithSelectors<S>['use']; // Присваиваем корректный тип для store.use

    for (const k of Object.keys(store.getState()) as Array<keyof ReturnType<S['getState']>>) {
        store.use[k] = () => store((s) => s[k]);
    }

    return store;
};

export const useModeStore = createSelectors(useStore);
export default useStore;
