import React from 'react';
import clsx from 'clsx'; // Используем clsx для удобного объединения классов

interface ShinyButtonProps {
    text: string; // Пропс для текста кнопки
    className?: string; // Пропс для дополнительных стилей
}

const ShinyButton: React.FC<ShinyButtonProps> = ({ text, className }) => {
    return (
        <button className={clsx('shiny-cta', className)}>
            {' '}
            {/* Объединяем классы */}
            <span>{text}</span>
        </button>
    );
};

export default ShinyButton;
