import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface ToastProps {
    message: string; // Ожидаем строку для сообщения
    onClose: () => void;
    type: 'success' | 'error'; // Тип уведомления: успех или ошибка
}

const Toast: React.FC<ToastProps> = ({ message, onClose, type }) => {
    const [isVisible, setIsVisible] = useState(true);
    const duration = 3000; // Время показа уведомления в миллисекундах

    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(false), duration); // Скрываем через 3 секунды
        const removeTimer = setTimeout(onClose, duration + 500); // Удаляем после завершения анимации

        return () => {
            clearTimeout(timer);
            clearTimeout(removeTimer);
        };
    }, [onClose, duration]);

    const backgroundColor = type === 'error' ? 'bg-red-500' : 'bg-blue-500'; // Цвет фона в зависимости от типа
    const progressColor = type === 'error' ? 'bg-red-300' : 'bg-blue-300'; // Цвет прогрессбара в зависимости от типа

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    transition={{ duration: 0.5 }}
                    className={`fixed bottom-5 right-5 z-50 ${backgroundColor} text-white p-4 rounded-lg shadow-lg flex flex-col`}
                >
                    <div className='flex justify-between items-center'>
                        <span>{message}</span>
                        <button onClick={() => setIsVisible(false)} className='ml-4 text-white focus:outline-none'>
                            &times;
                        </button>
                    </div>
                    {/* Прогрессбар */}
                    <div className='relative w-full h-1 mt-2 overflow-hidden'>
                        <div
                            className={`absolute top-0 left-0 h-1 ${progressColor}`}
                            style={{
                                width: '100%',
                                animation: `progress ${duration}ms linear`,
                            }}
                        />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Toast;
